import { useEffect, useState } from 'react';
import { IPageData, IPageProps } from '../../../interfaces/page-data';
import AltisDataGrid, { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { IApplication } from '../../../interfaces/IApplicationData';

const ApplicationManagement: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const [applicationData, setApplicationData] = useState<IApplication>();

  const pageData: IPageData = {
    title: 'Application Management',
    loaded: true,
  };

  useEffect(() => {
    onSetPage(pageData);
    ApplicationFilter();
  }, []);

  const columns: IAltisDataGridColumns[] = [
    {
      title: 'Application Name',
      key: 'applicationName',
      placeHolder: 'Application Name',
      inputType: 'input',
      align: 'left',
    },
    {
      title: 'Application Explanation',
      key: 'applicationExplanation',
      placeHolder: 'Application Explanation',
      inputType: 'input',
      align: 'left',
    },
    {
      title: 'Operations',
      key: 'operations',
      placeHolder: 'operations',
      inputType: 'operations',

      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
           <Space size={5}>
          <Popconfirm
            title={<div>Are you sure you want to delete the application?</div>}
            onConfirm={() => {
              DeleteApplication(record.applicationId);
              console.log(record);
            }}
            okText='Yes'
            cancelText='No'>
            <Tooltip title='Delete'>
              <Button
                size='middle'
                icon={<DeleteTwoTone />}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                disabled={props.loginUserDetails.role !== 'AltisAdmin'}></Button>
            </Tooltip>
          </Popconfirm>
          <Tooltip title='Update'>
            <Button
              size='middle'
              icon={<EditTwoTone />}
              onClick={() => {
                const applicationId = record.applicationId;
                window.location.replace('/admin/update-application/' + applicationId);
              }}></Button>
          </Tooltip>
          </Space>
        </div>
      ),
    },
  ];

  const DeleteApplication = (id: any) => {
    HTTPService.DELETE(`/Application/deleteApplication?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success('Application deletion successful.');
        window.location.reload();
      } else {
        toast.error('Application deletion failed.');
      }
    });
  };
  const ApplicationFilter = (e?: any) => {
    var body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      applicationName: e?.applicationName,
      applicationExplanation: e?.applicationExplanation,
    };
    console.log(body);
    HTTPService.POST(`/Application/ApplicationFilter`, body)
      .then((response) => {
        console.log(response);
        if (!response.data) {
          return;
        }
        let tmp: IApplication = JSON.parse(response.data);
        console.log(tmp);
        tmp.data?.map((elem, index) => {
          elem.key = index;
        });
        setApplicationData(tmp);
      })
      .catch((err) => {
        console.error(err);
        toast.error('An error occurred');
      });
  };

  return (
    <>
      <div className='row'>
        <div className='col-md-4 mb-3'>
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '5px 30px 30px 5px', marginBottom: 20 }}
            size='middle'
            onClick={() => window.location.replace('/admin/add-application')}>
            Add Application
          </Button>
        </div>
      </div>
      <div className='row'>
        {applicationData != null ? (
          <div className='col-md-12'>
            {
              <AltisDataGrid
                data={applicationData!.data}
                total={applicationData!.count}
                columns={columns}
                onChange={ApplicationFilter}
              />
            }
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default ApplicationManagement;
