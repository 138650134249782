import React from 'react';

import {publicRoutes} from '../../routes';
import {Route} from 'react-router';

import Logo from '../components/Logo/Logo';

import './Public.scss';
import {Toaster} from "react-hot-toast";

const PublicLayout = props => (
    <div className='public-layout'>
        <div className='bg'/>

        <div className='content-box'>
            <div className='content-header'>
                {/* <Logo light width={200} /> */}
                <Logo style={{  }} height={33} width={300} source='altisLogoBeyaz'/>
            </div>

            <Toaster
                position="top-right"
                toastOptions={{
                    style: {
                        width: '240px',
                        height: '50px',
                        fontSize: '14px',
                        color: 'white'
                    },
                    success: {
                        duration: 3000,
                        iconTheme: {
                            primary: '#65CC6D',
                            secondary: 'white'
                        },
                        style: {
                            background: '#65CC6D',
                        }
                    },
                    error: {
                        iconTheme: {
                            primary: '#FF6961',
                            secondary: 'white'
                        },
                        duration: 3000,
                        style: {
                            background: '#FF6961',
                        },
                    },
                    custom: {
                        iconTheme: {
                            primary: '#FFA500',
                            secondary: 'white'
                        },
                        duration: 3000,
                        style: {
                            background: '#FFA500',
                        },
                    }
                }}
            />

            <div className='content-body'>
                {publicRoutes.map((route, i) => (
                    <Route
                        exact
                        key={i}
                        path={`/public${route.path}`}
                        render={() => <route.component onSetPage={props.onSetPage}/>}
                    />
                ))}
            </div>
        </div>
    </div>
);

export default PublicLayout;
