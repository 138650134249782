import axios from 'axios';

import {API_URL, API_REQUEST_TIMEOUT} from "../utils/Constants";

export interface IResponse {
    data: string,
    status: number,
    errorText: string,
    dateTime: string
}

//TODO: AdminID: id redux

axios.defaults.baseURL = API_URL;
axios.defaults.timeout = API_REQUEST_TIMEOUT;
//axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
//axios.defaults.headers = { 'Content-Type': 'application/json; charset=utf-8' };
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        let response: IResponse = {} as IResponse;
        response.dateTime = error.response.headers['server-date'];
        if (error.response === typeof undefined) {
            // API'in cevap donemedigi durumlarda yasanan hata
            response.status = 0;
            response.errorText = error.message;
        }
        else if (error.response.status === 401|| error.response.status ===403) {
            // User unauthorized
            response.status = 401;
            response.errorText = error.response.statusText;
            window.location.replace('/public/sign-in');
           
        }      
        else if (error.response.status === 400) {
            // User unauthorized
            console.log("fdsfsd")
            response.data = error.response.data;
            response.status = 400;
            response.errorText = error.response.statusText;
            //window.location.replace('/public/sign-in');
           
        }   
        else {
            response.status = error.response.status;
            if (error.response.data == null)
                response.errorText = error.response.statusText;
            //let t = JSON.parse(error.response.data);
            //response.errorText = t.message;
            response.errorText = error.response.data.message;
        }
        return Promise.reject(response);
    }
);

class HTTPService {
    static API_URL: any = API_URL;

    static LOGIN = (URL: string, query: object = {}) => {
        query = query || {};

        let response: IResponse = {} as IResponse;
        return axios
            .get(URL,{
                params: query
            })
            .then((result) => {
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }

    static GET = (URL: string, query: object = {}) => {
        query = query || {};
        let response: IResponse = {} as IResponse;
        return axios
            .get(URL,{
                params: query,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true"
                },
            })
            .then((result) => {
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }

    static POST = (URL: string, data: Object, query? :Object) => {
        let response: IResponse = {} as IResponse;
        return axios
            .post(URL, data,{
                params: query,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true"
                },
            })
            .then((result) => {
                //console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }

    static PUT = (URL: string, data: Object) => {
        let response: IResponse = {} as IResponse;
        console.log(URL);
        return axios
            .put(URL, data,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true"
                },}
                )
            .then((result) => {
                console.log(result)
                //console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }

    static DELETE = (URL: string) => {
        let response: IResponse = {} as IResponse;
        return axios
            .delete(URL,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true"
                },}
            )
            .then((result) => {
                console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
               
                return response;
            })
            .catch((result) => {
                console.log(result);
                return result;
            });
         
    }

    static GETBlob = async (URL: string,  query?: Object) => {
       
       
        return axios
            .get(URL, {
                params: query,
                headers: {
                    "responseType":"blob",
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            .then((result) => {
                console.log(result);
                new File([result.data], "ssad");    
            })
            .catch((response) => {
                return response;
            });
    }
    
}

export default HTTPService;