
import { IPageProps } from "../../../interfaces/page-data";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { Button, Card, Col, Form, Input, Row } from "antd";
import {  UserOutlined } from "@ant-design/icons";



const AddApplication: React.FunctionComponent<IPageProps> = (props) => {
  
    
    const ApplicationRegister = (values: any) => {
      
      const newDevice = {
        applicationName: values.applicationName,
        applicationExplanation: values.applicationExplanation,
      };
  
      HTTPService.POST(`/Application/ApplicationRegister`, newDevice)
        .then((response) => {
          if (response.status === 200) {
            toast.success('Application Add Successfully');
            window.location.replace('/admin/application-management');
          } else {
            toast.error(response.data);
          }
        })
        .catch((error) => {
          console.error('Application failed: ', error);
        });
    };
  
    const handleCancel = () => {
      window.location.replace('/admin/application-management/');
    };
  
    return (
      <Card style={{ padding: '50px' }}>
        <h3>Register Application</h3>
        <Form layout='vertical' onFinish={ApplicationRegister}>
          
          <Form.Item
            name='applicationName'
            rules={[
              {
                required: true,
                message: 'Please input your Application Name!',
                whitespace: true,
              },
            ]}>
            <Input prefix={<UserOutlined />} placeholder='Application Name' style={{ borderRadius: 0 }} />
          </Form.Item>
  
          <Form.Item
            name='applicationExplanation'
            rules={[
              {
                required: true,
                message: 'Please input your Application Explanation!',
                whitespace: true,
              },
            ]}>
            <Input prefix={<UserOutlined />} placeholder='Application Explanation' style={{ borderRadius: 0 }} />
          </Form.Item>
  

          <Form.Item>
            <Row gutter={[8, 8]} justify='end' align='middle'>
              <Col xs={12} sm={4}>
                <Button block onClick={handleCancel} type='primary' style={{ borderRadius: 0 }}>
                  Cancel
                </Button>
              </Col>
              <Col xs={12} sm={4}>
                <Button block htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
                  Register
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    );
  };
  export default AddApplication;
  