import React, { useEffect, useState } from 'react';
import { IPageData, IPageProps } from '../../interfaces/page-data';
import { Button, Input, Popconfirm, Select, Space, Tooltip } from 'antd';
import { toast } from 'react-hot-toast';
import HTTPService from '../../utils/makerequest';
import { DeleteOutlined, DeleteTwoTone, EditOutlined, EditTwoTone } from '@ant-design/icons';
import AltisDataGrid from '../../AltisComponent/AltisDataGrid';
import { IAltisDataGridColumns } from '../../AltisComponent/AltisDataGrid';

interface IUserData {
  key?: number;
  firstName: string;
  password?: string;
  isDeleted?: boolean;
}

interface IUsers {
  count: number;
  data: IUserData[];
}

const PagePalletData: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const [userData, setUserData] = useState<IUsers>();

  const pageData: IPageData = {
    title: 'User Settings',
    loaded: true,
  };
  useEffect(() => {
    onSetPage(pageData);
    UserFilter();
  }, []); //onSetPage, pageData

  const UserFilter = (e?: any) => {
    console.log(e);
    var body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      companyName: e?.companyName,
      userFirstName: e?.firstname,
      userLastName: e?.lastname,
      userEmail: e?.email,
      role: e?.role,
      companyId: e?.companyId,
    };

    HTTPService.POST(`/account/UserFilter`, body).then((response) => {
      if (!response.data) {
        return;
      }
      let tmp: IUsers = JSON.parse(response.data);
      tmp.data = tmp.data?.filter((elem) => !elem.isDeleted);
      tmp.data.map((elem, index) => {
        elem.key = index;
      });
      setUserData(tmp);
    });
  };

  const DeleteUser = (id: any, email: any) => {
    HTTPService.DELETE(`/account/delete?Id=${id}`).then((response) => {
      if (!response.data) {
        return;
      }
      toast.success('User Deleted Successfully');
      window.location.reload();
    });
  };
  const columns: IAltisDataGridColumns[] = [
    // {
    //   title: 'Company Name',
    //   key: 'companyName',
    //   placeHolder: "Company Name",
    //   inputType:"input",
    // },
    {
      title: 'E-Mail Adres',
      key: 'email',
      placeHolder: 'E-Mail',
      inputType: 'input',
      align: 'left',
    },
    {
      title: 'Firstname',
      key: 'firstname',
      placeHolder: 'Firstname',
      inputType: 'input',
      align: 'left',
    },
    {
      title: 'Lastname',
      key: 'lastname',
      placeHolder: 'Lastname',
      inputType: 'input',
      align: 'left',
    },
    {
      title: 'Role',
      key: 'role',
      placeHolder: 'Role',
      inputType: 'input',
      align: 'left',
    },
    {
      title: 'Operations',
      key: 'operations',
      placeHolder: 'operations',
      inputType: 'operations',
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Space size={5}>
            {props.loginUserDetails.email !== record.email && (
              <Popconfirm
                title='Are you sure you want to delete this user?'
                onConfirm={() => {
                  DeleteUser(record.userId, record.email);
                }}
                okText='Yes'
                cancelText='No'>
                <Tooltip title='Delete'>
                  <Button
                    size='middle'
                    icon={<DeleteTwoTone />}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}></Button>
                </Tooltip>
              </Popconfirm>
            )}
            <Tooltip title='Update'>
              <Button
                size='middle'
                icon={<EditTwoTone />}
                onClick={() => {
                  window.location.replace('/admin/update-user/' + record.userId);
                }}>
                
              </Button>
            </Tooltip>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className='row'>
        <div className='col-md-4 mb-3'>
          {props.loginUserDetails.role === 'AltisAdmin' && (
            <Button
              type='primary'
              shape='round'
              style={{ height: 40, borderRadius: '5px 30px 30px 5px', marginBottom: 20 }}
              size='middle'
              onClick={() => window.location.replace('/admin/add-user')}>
              Add New User
            </Button>
          )}
        </div>
      </div>
      <div className='row'>
        {userData != null ? (
          <div className='col-md-12'>
            <AltisDataGrid
              data={userData!.data}
              total={userData!.count}
              columns={columns}
              onChange={UserFilter}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default PagePalletData;
