import React from 'react';

import WrappedForm from './SignInForm';

import './SignIn.scss';

const PageSignIn = () => {
    return (
        <>
            <div className='title-block'>
                <h5 className='form-title'>Sign In</h5>
            </div>
            <WrappedForm/>
        </>
    );
};

export default PageSignIn;
