import { Button, Card, Form, Input } from 'antd';
import { toast } from 'react-hot-toast';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import HTTPService from '../../../utils/makerequest';
import { IPageProps } from '../../../interfaces/page-data';
import { IAuthentication } from '../../../interfaces/authentication';

const EmailChange: React.FunctionComponent<IPageProps> = (props) => {
  const logout = () => {
    HTTPService.GET(`/Login/Logout`)
      .then((response) => {
        if (!response.data) {
          console.error('Çıkış işlemi başarısız:', response);
          return;
        }
        console.log('Çıkış işlemi başarılı.');
        window.location.replace('/public/sign-in/');
      })
      .catch((error) => {
        console.error('LogOut hatası:', error);
      });
  };

  const update = (e: any) => {
    HTTPService.PUT(
      `/Account/UpdateEmail?email=${props.loginUserDetails.email}&newEmail=${e.newEmail}&password=${e.Password}`,
      {}
    )
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success('Email updated successfully.');
          logout();
        } else {
          toast.error('Email or password is incorrect.');
        }
      })
      .catch((error) => {
        toast.error('There was an error!', error);
      });
  };
  return (
    <Card style={{ padding: '50px' }}>
      <h3>Change Email</h3>
      <Form layout='vertical' onFinish={update}>
        <Form.Item
          name='newEmail'
          rules={[
            {
              required: true,
              message: 'Please input your email!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'Email cannot contain spaces!',
            },
          ]}>
          <Input prefix={<MailOutlined />} placeholder='Email' style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          name='Password'
          rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password
            prefix={<LockOutlined />}
            placeholder='Password'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        <Form.Item>
          <div className='span d-flex justify-content-between'>
            <span />
            <Button htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
              Save changes
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default EmailChange;
