import React, {useEffect, useState} from 'react';
import {Route, Redirect, matchPath} from 'react-router-dom';

import {IRoute, routes} from './routes';
import './assets/sass/App.scss';
import './assets/sass/styles.scss';
import {store} from "./redux/store";

const App: React.FC = () => {
    const user = store.getState().user;
    const [route, setRoute] = useState<string>();

    useEffect(() => {
        let layout = "/" + window.location.pathname.split('/')[1];
        console.log(window.location.pathname)
        if(window.location.pathname === "/public/sign-in")
        {
            setRoute(window.location.pathname);
        }
        else if (pathIsExist()) {
            if (layout === "/") {
                setRoute('/admin/licence-management');
            }
            else {
                setRoute(window.location.pathname);
            }
        }
        else 
        {
            setRoute('/admin/licence-management');
        }
        // setRoute(window.location.pathname);
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    // Path is exist for all routes
    const pathIsExist = () => {
        let result: boolean = false;
        let splittedURL = window.location.pathname.split('/');
        let currentLayout = "/" + splittedURL[1];
        let currentPath = "/" + splittedURL[2];
        let selectedRoute :any[] | undefined = [];
        if (currentLayout.length < 1 || currentLayout === '/') {
            return true;
        }
        routes.map(route => {
            if (currentLayout.includes(route.path)) {
                selectedRoute = route.children;
                return;
            }
        })
        if (selectedRoute.length > 0) {
            selectedRoute.map((item: IRoute) => {
                if (item.path.includes(currentPath)) {
                    result = true;
                    return;
                }
            });
        }
        else {
            result = false;
        }

        return result;
    }

    const haveUser = () => {
        return Object.keys(user).length > 0;
    }

    const isChipseePage = (pageName: string) => {
        if(matchPath(pageName, {path: '/chipsee/dashboard'}) != null){
            return true;
        }
        return  false;
    }

    return (
        <div className='App'>
            {routes.map((route, i) => (
                <Route key={i} path={route.path} component={route.component}/>
                //<Route key={i} path='/' component={() => <route.component/>}/>
            ))}
            {/*<Route exact path='/' render={() => <Redirect exact to={route}/>}/>*/}
            {route && <Redirect exact to={route}/>}
        </div>
    );
};

export default App;
