import React, { Suspense, useEffect, useState } from 'react';
import axios from 'axios';

import '../BaseLayout/BaseLayout.scss';
import './Admin.scss';

import { connect } from 'react-redux';
import { Route } from 'react-router';

import { toggleSidebar } from '../../redux/settings/actions';

import BaseLayout from '../BaseLayout/BaseLayout';
import Navbar from '../components/Navbar/Navbar';
import Menu from '../components/Menu/Menu';
import Logo from '../components/Logo/Logo';
import Actions from '../components/Actions/Actions';
import NavbarSkeleton from '../components/NavbarSkeleton/NavbarSkeleton';

import { IPageData } from '../../interfaces/page-data';
import { IMenuItem } from '../../interfaces/menu';
import { IAppSettings } from '../../interfaces/settings';

import { AppState } from '../../redux/store';

import BasePage from '../../pages/BasePage/BasePage';

import { defaultRoutes } from '../../routes';

import { resetPageData, setPageData } from '../../redux/pages/actions';
import toast, { Toaster } from "react-hot-toast";
import { Button } from 'antd';
import { IAuthentication } from '../../interfaces/authentication';
import HTTPService from '../../utils/makerequest';

const getGradientString = (firstColor: string, secondColor: string): string =>
  `linear-gradient(188deg, ${firstColor}, ${secondColor} 65%)`;

interface AdminLayoutProps {
  pageData: IPageData;
  settings: IAppSettings;
  onSidebarToggle: () => void;
  onSettingsReset: () => void;
  onSettingsSet: (data: IAppSettings) => void;
  onSettingsUpdate: (data: IAppSettings) => void;
  onSetPage: (data: IPageData) => void;
  onPageReset: () => void;
  userData?: IAuthentication;
}

const AdminLayout: React.FunctionComponent<AdminLayoutProps> = props => {
  const [userData, setUserData] = useState<IAuthentication>();

  useEffect(() => {
    fetchUser();
  }, []);

  async function fetchUser() {
    try {
      console.log("sayfada veriyi tekrar çekti")
      HTTPService.GET('/login/IsUserLoggedIn')
        .then((response) => {
          
          if (response.status === 200) {
            
            setUserData(JSON.parse(response.data));
          }
          else {
            window.location.replace("/public/sign-in");
          }
        })
        .catch(err => {
          console.error(err);
          toast.error("Bir Hata Oluştu");
        });
    }
    catch (ex) {
      console.error(ex)
    }
  }

  return (
    <div className='layout vertical'>

      {
        userData != null ?
        (
          <AdminLayoutProps
            pageData={props.pageData}
            settings={props.settings}
            onSidebarToggle={props.onSidebarToggle}
            onSettingsReset={props.onSettingsReset}
            onSettingsSet={props.onSettingsSet}
            onSettingsUpdate={props.onSettingsUpdate}
            onSetPage={props.onSetPage}
            onPageReset={props.onPageReset}
            userData={userData}
          />
        ) : <></>
      }

    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  pageData: state.pageData,
  settings: state.settings
});

const mapDispatchToProps = (dispatch: any) => ({
  onSidebarToggle: () => dispatch(toggleSidebar()),
  onSetPage: (data: IPageData) => dispatch(setPageData(data)),
  onPageReset: () => dispatch(resetPageData())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLayout);



const AdminLayoutProps: React.FunctionComponent<AdminLayoutProps> = props => {
  const { pageData, settings, onSidebarToggle, onPageReset, onSetPage } = props;

  const [menuData, setMenuData] = useState<IMenuItem[]>([]);
  const {
    sidebarAccentColor,
    sidebarColor,
    topbarColor,
    topbarBg,
    sidebarAccentContrastColor,
    sidebarOpened,
    boxed
  } = props.settings;

  useEffect(() => {
    fetchMenu();
  }, []);

  const handleToggleSidebar = () => {
    onSidebarToggle();
  };


  const fetchMenu = () => {
    try {
      axios({ baseURL: '', url: './data/menu.json', method: 'get' })
        .then(result => {
          const data: IMenuItem[] = result.data;
          const adminMenuItems = data.filter(item => item.role?.includes(props.userData.role));
          setMenuData(adminMenuItems);
        });

    } catch (err) {
      console.log('Server Error', err);
      setMenuData([]);
    }
  }

  return (
    <div className={`app-container ${boxed && 'boxed'}`}>
      <Navbar
        style={{ backgroundImage: getGradientString("#00d4ff", "#090979") }}
        orientation='vertical'
        opened={sidebarOpened}
        onCloseNavbar={handleToggleSidebar}>
        <Button
          className='no-style navbar-close icofont-close-line d-lg-none'
          onClick={onSidebarToggle}
        />
        <Logo style={{ backgroundColor: topbarBg }} padding={17} marginLeft={5} height={60} width={250} source='rubberLogo' />
        <Menu
          color={sidebarColor}
          accentContrast={sidebarAccentContrastColor}
          accentColor={sidebarAccentColor}
          data={menuData}
          layout={'admin'}

        />
        <Logo padding={39}  source='altisbeyaz' />
        <NavbarSkeleton type='vertical' loaded={true} />
      </Navbar>

      <Navbar
        boxed={boxed}
        style={{ backgroundColor: topbarBg, color: topbarColor }}
        orientation='horizontal'
        className='horizontal-nav'
        minHeight={60}>
        <Button className='navbar-toggle d-lg-none' onClick={handleToggleSidebar}>
          <span />
          <span />
          <span />
        </Button>

        <Actions 
          userfirstname={props.userData?.firstname}
          userlastname={props.userData?.lastname}
        />

        <NavbarSkeleton type='horizontal' loaded={true} />
      </Navbar>

      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            width: '280px',
            height: '50px',
            fontSize: '14px',
            color: 'white'
          },
          success: {
            duration: 3000,
            iconTheme: {
              primary: '#65CC6D',
              secondary: 'white'
            },
            style: {
              background: '#65CC6D',
            }
          },
          error: {
            iconTheme: {
              primary: '#FF6961',
              secondary: 'white'
            },
            duration: 3000,
            style: {
              background: '#FF6961',
            },
          },
          custom: {
            iconTheme: {
              primary: '#FFA500',
              secondary: 'white'
            },
            duration: 3000,
            style: {
              background: '#FFA500',
            },
          }
        }}
      />

      <BaseLayout
        pageData={pageData}
        settings={settings}
        onPageReset={onPageReset}
        onSidebarToggle={onSidebarToggle}>
        {defaultRoutes.map((route, i) => {
          return (
            <Route
              exact
              key={i}
              path={`/admin${route.path}`}
              render={() => (
                <BasePage>
                  {
                    props.userData != null ? (
                      <>
                        <route.component onSetPage={onSetPage} loginUserDetails={props.userData} />
                      </>
                    ) : null
                  }
                </BasePage>
              )}
            />
          );
        })}
      </BaseLayout>
    </div>

  )
};