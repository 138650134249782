import React, { useEffect, useState } from 'react';

import { Menu, Dropdown, Avatar } from 'antd';

import './Actions.scss';
import HTTPService from '../../../utils/makerequest';

interface ActionsProps {
  userfirstname?: string;
  userlastname?: string;
}
const Actions: React.FunctionComponent<ActionsProps> = (props) => {
  const logout = () => {
    HTTPService.GET(`/Login/Logout`)
      .then((response) => {
        if (!response.data) {
          console.error('Çıkış işlemi başarısız:', response);
          return;
        }
        console.log('Çıkış işlemi başarılı.');
        window.location.replace('/public/sign-in/');
      })
      .catch((error) => {
        console.error('LogOut hatası:', error);
      });
  };
  const resetPassword = () => {
    window.location.replace('/admin/reset-password');
  };
  const changeEmail = () => {
    window.location.replace('/admin/change-email');
  };
  const companyManagement = () => {
    window.location.replace('/admin/company-management');
  };
  const accountMenu = (
    <Menu style={{ minWidth: '220px' }}>
      <Menu.SubMenu key={'3'} title='Profil' style={{ marginLeft: 7 }}>
        <Menu.Item key={'3.1'} onClick={resetPassword}>
          Reset Password
        </Menu.Item>
        <Menu.Item key={'3.2'} onClick={changeEmail}>
          Change Email
        </Menu.Item>
        <Menu.Item key={'3.3'} onClick={companyManagement}>
          Company Management
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key={'4'}>
        <span className='d-flex d-flex justify-content-between align-item-center'>
          <a className='d-flex w-100' onClick={logout}>
            Log out
          </a>
          <span className='d-flex align-items-center icofont-logout' />
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='actions'>
      <div>
        {props.userfirstname} {props.userlastname}
      </div>
      <Dropdown overlay={accountMenu} trigger={['click']}>
        <div className='item'>
          <Avatar className='mr-1' src={null} />
          <span className='icofont-simple-down' />
        </div>
      </Dropdown>
    </div>
  );
};

export default Actions;
