import { Form, Input } from 'antd';

const CalLicenseTag: React.FunctionComponent<any> = (props) => {
  return (
    <>

      <Form.Item
        name='deviceLimit'
        rules={[
          {
            required: true,
            message: 'Please enter the Device Limit!',
          },
        ]}>
        <Input placeholder='Device Limit' style={{ borderRadius: 0 }} />
      </Form.Item>
    </>
  );
};
export default CalLicenseTag;
