import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { IPageProps } from '../../interfaces/page-data';
import HTTPService from '../../utils/makerequest';
import { toast } from 'react-hot-toast';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IUserData } from '../../interfaces/IUserData';
import { ICompany } from '../../interfaces/ICompany';


const UpdateUser: React.FunctionComponent<IPageProps> = (props) => {
  const { userId } = useParams<any>();
  const [userData, setUserData] = useState<IUserData>();
  const [companyData, setCompanyData] = useState<ICompany[]>();

  useEffect(() => {
    GetUserData(userId);
    //AllCompanyName();
  }, [userId]);

  // const AllCompanyName = () => {
  //   HTTPService.GET(`/Company/AllCompanyNames`)
  //     .then((response) => {
  //       if (!response.data) {
  //         return;
  //       }
  //       let tmp: ICompany[] = JSON.parse(response.data);
  //       console.log(tmp);
  //       tmp.map((elem, index) => {
  //         elem['key'] = index;
  //       });
  //       console.log(tmp);
  //       setCompanyData(tmp);
  //     })
  //     .catch((error) => {
  //       console.error('Grup adları çekme hatası:', error);
  //     });
  // };
  const update = (e: any) => {
    let companyIdToUse;
    if (props.loginUserDetails.role.includes('AltisAdmin')) {
      companyIdToUse = e.selectedCompany;
      console.log(companyIdToUse);
    } else {
      companyIdToUse = props.loginUserDetails.companyId;
    }
    const updatedUser = {
      email: e.email,
      firstName: e.firstname,
      lastName: e.lastname,
      companyId: companyIdToUse,
      role:e.role,
    };

    HTTPService.PUT(`/Account/UpdateUser?userId=${userId}`, updatedUser)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success('Updated Group.');
          window.location.replace('/admin/user-settings');
        } else {
          toast.error('User could not be updated');
        }
      })
      .catch((error) => {
        toast.error('There was an error!', error);
      });
  };

  const GetUserData = (userId: number) => {
    HTTPService.GET(`/Account/GetUserUpdate?userId=` + userId)
      .then((res) => {
        if (!res.data) {
          return;
        }
        console.log(res.data);
        let tmp: IUserData = JSON.parse(res.data);

        setUserData(tmp);
      })
      .catch((error) => {
        console.error('API çağrısında bir hata oluştu:', error);
      });
  };
  const handleCancel = () => {
    window.location.replace('/admin/user-settings/');
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>Update User</h3>
      {}
      <Form
        layout='vertical'
        onFinish={update}
        fields={[
          {
            name: ['email'],
            value: userData?.email,
          },
          {
            name: ['firstname'],
            value: userData?.firstname,
          },
          {
            name: ['lastname'],
            value: userData?.lastname,
          },
          // {
          //   name: ['selectedCompany'],
          //   value: userData?.companyId,
          // },
          {
            name: ['role'],
            value: userData?.role,
          },
        ]}>
        {/* {props.loginUserDetails.role === 'AltisAdmin' ? (
          <Form.Item name='selectedCompany'>
            <Select showSearch placeholder='Company Name' style={{ marginBottom: '6px' }}>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null} */}

        <Form.Item
          name='email'
          rules={[
            {
              required: true,
              message: 'Please input your E-Mail!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'E-Mail cannot contain spaces!',
            },
          ]}>
          <Input prefix={<MailOutlined />} placeholder='E-Mail' style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          name='firstname'
          rules={[
            {
              required: true,
              message: 'Please input your firstName!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'firstName cannot contain spaces!',
            },
          ]}>
          <Input prefix={<UserOutlined />} placeholder='firstName' style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          name='lastname'
          rules={[
            {
              required: true,
              message: 'Please input your lastName!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'LastName cannot contain spaces!',
            },
          ]}>
          <Input prefix={<UserOutlined />} placeholder='lastName' style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          name='role'
          rules={[
            {
              required: true,
              message: 'Please select your Role!',
            },
          ]}>
          <Select showSearch placeholder='Role'>
          {props.loginUserDetails.role.includes("AltisAdmin") ? (
            <Select.Option value={0}>AltisAdmin</Select.Option>
            ) : null}
            <Select.Option value={1}>Admin</Select.Option>
            {/* <Select.Option value={2}>User</Select.Option> */}
          </Select>
        </Form.Item>
        {/* <Form.Item
          name='permissionGroupId'
          rules={[{ required: true, message: 'Please input your Group Name!' }]}
        >
          <Select
            placeholder='Select a Group Name'
            className='rounded-select'
            style={{ borderRadius: '20px !important' }}
          >
            {Array.isArray(groupData) &&
              groupData.map((group) => (
                <Select.Option key={group.groupId} value={group.groupId}>
                  {group.groupsName}
                </Select.Option>
              ))}

          </Select>
        </Form.Item> */}

        <Form.Item>
          <Row gutter={[8, 8]} justify='end' align='middle'>
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type='primary' style={{ borderRadius: 0 }}>
                Cancel
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
                Save Changes
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default UpdateUser;
