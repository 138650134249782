import { useEffect, useRef, useState } from 'react';
import { IDevice, IDeviceLicense } from '../../../interfaces/deviceData';
import { IPageData, IPageProps } from '../../../interfaces/page-data';
import { Button, Form, Input, Modal, Popconfirm, Space, Tooltip, Upload } from 'antd';
import {
  DeleteTwoTone,
  EditTwoTone,
  FilePdfOutlined,
  LockTwoTone,
  ReloadOutlined,
  UnlockTwoTone,
  UploadOutlined,
} from '@ant-design/icons';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import AltisDataGrid, { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { API_URL } from '../../../utils/Constants';


const DeviceLicense: React.FunctionComponent<IPageProps> = (props) => {
  const { licenseId } = useParams<any>();
  const { onSetPage } = props;
  const [licenseData, setLicenseData] = useState<IDevice>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [generateVisible, setGenerateVisible] = useState(false);
  const [voidVisible, setVoidVisible] = useState<number>(-1);
  const [token, setToken] = useState('');
  const [deviceId, setDeviceId] = useState(null);
  const [licenseToken, setLicenseToken] = useState<number>();
  const [deleteVisible, setDeleteVisible] = useState(false);
  const formRef = useRef(null);

  const pageData: IPageData = {
    title: 'Licence Details',
    loaded: true,
  };

  useEffect(() => {
    onSetPage(pageData);
    DeviceFilter(licenseId);
  }, [licenseId]);

  const columns: IAltisDataGridColumns[] = [
    {
      title: 'Company Name',
      key: 'companyName',
      placeHolder: 'Company Name',
      inputType: 'input',
      align:'left'
    },
    {
      title: 'Licence Name',
      key: 'licenseName',
      placeHolder: 'Licence Name',
      inputType: 'input',
      align:'left'
    },
    {
      title: 'Device Name',
      key: 'deviceName',
      placeHolder: 'Device Name',
      inputType: 'input',
      align:'left'
    },
    {
      title: 'Mac Address',
      key: 'macAddress',
      placeHolder: 'Mac Address',
      inputType: 'input',
      hide: props.loginUserDetails.role.includes('AltisAdmin'),
    },
    {
      title: 'HDD Serial Number',
      key: 'hddSerialNumber',
      placeHolder: 'HDD Serial Number',
      inputType: 'input',
      hide: props.loginUserDetails.role.includes('AltisAdmin'),
    },
    {
      title: 'CPU Serial Number',
      key: 'cpuSerialNumber',
      placeHolder: 'CPU Serial Number',
      inputType: 'input',
      hide: props.loginUserDetails.role.includes('AltisAdmin'),
    },
    {
      title: 'Device Serial Number',
      key: 'deviceSerialNumber',
      placeHolder: 'Device Serial Number',
      inputType: 'input',
    },
    {
      title: 'Operations',
      key: 'operations',
      placeHolder: 'operations',
      inputType: 'operations',
      width: 2000,
      render: (order, record: IDeviceLicense) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
           <Space size={2}>
          <Popconfirm
            title='Are you sure you want to delete this device?'
            onConfirm={() => {
              DeleteDevice(record.deviceId);
              console.log(record);
            }}
            okText='Yes'
            cancelText='No'>
            <Tooltip title='Delete'>
              <Button
                size='middle'
                icon={<DeleteTwoTone />}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                disabled={props.loginUserDetails.role !== 'AltisAdmin'}></Button>
            </Tooltip>
          </Popconfirm>
          <Tooltip title='Update'>
            <Button
              // shape='circle'
              size='middle'
              icon={<EditTwoTone />}
              onClick={() => {
                const deviceId = record.deviceId;
                window.location.replace('/admin/update-device/' + deviceId);
              }}
              disabled={props.loginUserDetails.role !== 'AltisAdmin'}></Button>
          </Tooltip>
          <Tooltip title='Licence Key'>
            <Button
              size='middle'
              //  icon={<KeyOutlinedIcon style={{ color: '#1890ff' }} />}
              onClick={() => {
                const deviceId = record.deviceId;
                setDeviceId(deviceId);
                handleTokenCreation(deviceId);
                console.log(record);
              }}
              style={{
                display:
                  record.macAddress === null &&
                  record.hddSerialNumber === null &&
                  record.cpuSerialNumber === null
                    ? 'none'
                    : 'inline-block',
                padding: 0,
                width: 40,
              }}>
                <i className="icofont-key" style={{ color: '#1890ff' }}></i>
              </Button>
          </Tooltip>
          <Tooltip title='Actived Licence'>
            <Button
              size='middle'
              icon={<LockTwoTone />}
              onClick={() => {
                const deviceId = record.deviceId;
                //GenerateToken(deviceId);
                setDeviceId(deviceId);
                setGenerateVisible(true);
                console.log(deviceId);
              }}
              style={{
                display:
                  record.macAddress === null &&
                  record.hddSerialNumber === null &&
                  record.cpuSerialNumber === null
                    ? 'inline-block'
                    : 'none',
                padding: 0,
                width: 40,
              }}></Button>
          </Tooltip>
          <Tooltip title='Transfer Licence'>
            <Button
              // shape='circle'
              size='middle'
              icon={<UnlockTwoTone />}
              onClick={() => {
                const tmp = record.deviceId;
                //VoidLicense(deviceId,"");
                setVoidVisible(tmp);
                //VoidLicense({ deviceId: tmp });
                window.location.replace('/admin/transfer-licence/' + tmp);
              }}
              style={{
                display:
                  record.macAddress === null &&
                  record.hddSerialNumber === null &&
                  record.cpuSerialNumber === null
                    ? 'none'
                    : 'inline-block',
                padding: 0,
                width: 40,
              }}></Button>
          </Tooltip>

          {props.loginUserDetails.role.includes('AltisAdmin') ? (
            <Popconfirm
              title='The license for the device will be deleted. Are you sure you want to ReActivited?'
              onConfirm={() => {
                ReActiveted(record.deviceId);
              }}
              okText='Yes'
              cancelText='No'>
              <Tooltip title='ReActivited'>
                <Button
                  // shape='circle'
                  size='middle'
                  icon={<ReloadOutlined style={{ color: '#1890ff' }} />}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}></Button>
              </Tooltip>
            </Popconfirm>
          ) : null}

          <Tooltip title='Export Licence Certificate'>
                <Button
                  // shape='circle'
                  size='middle'
                  icon={<FilePdfOutlined style={{ color: '#1890ff' }} />}
                  onClick={() => {
                    DownloadPDF();
                  }}></Button>
              </Tooltip>
          </Space>
        </div>
      ),
    },
  ];



  const DownloadPDF =  () => {
   
         fetch(API_URL+`/License/LicencePDFExport?licenseId=${licenseId}`,{
          method: 'GET',
          credentials:'include',
          headers: {

            'Content-Type': 'application/json',
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'LicenceCertificate.pdf';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error('PDF dosyası indirilemedi:', error);
          });
      };




  const GenerateToken = (values) => {
    var body = {
      deviceId: deviceId,
      deviceToken: values.deviceToken,
    };
    console.log(body);
    HTTPService.POST(`/Token/generate`, body)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Actived Successfully');
          //window.location.reload();
          handleTokenCreation(deviceId);
          setDeleteVisible(true);
        } else if (response.status === 400) {
          toast.error(response.data);
        }
      })
      .catch((error) => {
        console.error('Token Error:', error);
      });

    console.log('Modal görünür hale geldi');
  };

  const handleUploadChange = (info) => {
    console.log(info.file.status);
    if (info.file.status !== 'uploading') {
      const formData = new FormData();
      formData.append('deviceId', deviceId);
      formData.append('fileToken', info.file.originFileObj);

      HTTPService.POST(`/Token/generate/file?deviceId=${deviceId}`, formData)
        .then((response) => {
          if (response.status === 200) {
            toast.success('File uploaded successfully');
            handleTokenCreation(deviceId);
          }
        })
        .catch((error) => {
          toast.error('Filer error');
        });
    }
  };

  const Download = async () => {
    try {
      const response = await axios.get(`/Token/download?deviceId=${deviceId}`, {
        responseType: 'blob', // Dosyanın byte olarak gelmesini sağlar
      });

      if (response.status === 200) {
        let fileName = 'KeyFile';

        // file-saver kütüphanesini kullanarak dosyayı kaydet
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        saveAs(blob, fileName);
      }
    } catch (error) {
      console.error('Download Error:', error);
    }
  };

  const handleTokenCreation = (deviceId) => {
    HTTPService.GET(`/Token`, { deviceId })
      .then((response) => {
        if (response.status === 200) {
          const tmp = JSON.parse(response.data);
          const deviceToken = tmp.token;
          console.log(deviceToken);
          setToken(deviceToken);
          setIsModalVisible(true);
        } else if (response.status === 400) {
          toast.error(response.data);
        }
      })
      .catch((error) => {
        console.error('Token Error:', error);
      });

    console.log('Modal görünür hale geldi');
  };

  const handleCopyToClipboard = () => {
    // Kopyalanacak metni panoya kopyala
    navigator.clipboard.writeText(token);
    toast.success('Token copied to clipboard');
  };
  const DeleteDevice = (id: any) => {
    HTTPService.DELETE(`/Device/deleteDevice?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success('Device deletion successful.');
        window.location.reload();
      } else {
        toast.error('Device deletion failed.');
      }
    });
  };

  const ReActiveted = (deviceId: any) => {
    HTTPService.DELETE(`/Device/ReActiveted?deviceId=${deviceId}`).then((response) => {
      if (response.status === 200) {
        toast.success('Device Reactiveted successful.');
        window.location.reload();
      } else {
        toast.error('Device Reactiveted failed.');
      }
    });
  };
  const DeviceFilter = (e?: any) => {
    var body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      filterDeviceName: e?.deviceName,
      filterMacAddress: e?.macAddress,
      filterHDDSerialNumber: e?.hddSerialNumber,
      filterCPUSerialNumber: e?.cpuSerialNumber,
      filterDeviceSerialNumber: e?.deviceSerialNumber,
      companyId: e?.companyId,
      companyName: e?.companyName,
      licenseId: licenseId,
      licenseName: e?.licenseName,
    };
    console.log(body);
    HTTPService.POST(`/Device/DeviceFilter`, body)
      .then((response) => {
        console.log(response);
        if (!response.data) {
          return;
        }
        let tmp: IDevice = JSON.parse(response.data);
        tmp.data = tmp.data?.filter((elem) => !elem.isDeleted);
        tmp.data?.map((elem, index) => {
          elem.key = index;
        });
        setLicenseData(tmp);
      })
      .catch((err) => {
        console.error(err);
        toast.error('Bir Hata Oluştu');
      });
  };
  const handleModalClose = () => {
    // Modal'i kapat
    setGenerateVisible(false);
    setIsModalVisible(false);
    window.location.reload();
  };
  const GenerateClose = () => {
    // Modal'i kapat
    setGenerateVisible(false);
  };

  const handleFormSubmit = () => {
    formRef.current.submit(); // Form referansını kullanarak formu gönder
  };

  return (
    <>
      <div className='row'>
        <div className='col-md-4 mb-3'>
          {props.loginUserDetails.role === 'AltisAdmin' && (
            <Button
              type='primary'
              shape='round'
              style={{ height: 40, borderRadius: '5px 30px 30px 5px', marginBottom: 20 }}
              size='middle'
              onClick={() => window.location.replace('/admin/add-device')}>
              Add New Device
            </Button>
          )}
        </div>
      </div>
      <div className='row'>
        {licenseData != null ? (
          <div className='col-md-12'>
            {
              <AltisDataGrid
                data={licenseData!.data}
                total={licenseData!.count}
                columns={columns}
                onChange={DeviceFilter}
              />
            }
          </div>
        ) : (
          <></>
        )}
      </div>
      <Modal
        title={<span style={{ color: 'red' }}>Licence Key</span>}
        visible={isModalVisible}
        bodyStyle={{ width: 400, height: 400 }}
        footer={[
          <Button key='copyButton' onClick={handleCopyToClipboard}>
            Copy
          </Button>,
          <Button key='download' onClick={Download}>
            Download
          </Button>,
        ]}
        onCancel={handleModalClose}>
        <p style={{ width: 455 }}>{token}</p>
      </Modal>
      <Modal
        title={<span style={{ color: 'red' }}>Activation Response 1</span>}
        visible={generateVisible}
        footer={[null]}
        onCancel={GenerateClose}
        bodyStyle={{ width: 400, height: 400 }}>
        <Form ref={formRef} onFinish={GenerateToken} layout='vertical'>
          <Form.Item name='deviceToken' label='Enter the Key you received from the device'>
            <Input placeholder='Enter your device token' style={{ width: 470 }} />
          </Form.Item>

          <Form.Item
            name='uploadActiveted'
            label={'Upload File' + Array(8).fill('\u00A0').join('')}
            valuePropName='fileList'
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e?.fileList;
            }}
            style={{ marginBottom: '30px', width: '300px', height: '50px' }}>
            <Upload name='logo' listType='text' onChange={handleUploadChange}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>

          <div style={{ position: 'absolute', bottom: 0, right: 0, margin: '20px' }}>
            <Popconfirm
              title='Do you approve the licensing process?'
              onConfirm={handleFormSubmit}
              okText='Yes'
              cancelText='No'>
              <Button type='primary' htmlType='submit'>
                Next
              </Button>
            </Popconfirm>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default DeviceLicense;
