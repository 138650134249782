import { useEffect, useState } from 'react';
import { IPageProps } from '../../../interfaces/page-data';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { ICompany } from '../../../interfaces/ICompany';
import { IApplicationData } from '../../../interfaces/IApplicationData';
import CalLicenseTag from '../../../layouts/components/LicenseTypeProps/CalLicenseTag';

const AddLicense: React.FunctionComponent<IPageProps> = (props) => {
  const [companyData, setCompanyData] = useState<ICompany[]>();
  const [applicationId, setApplicationId] = useState<number | null>(null);
  const [application, setApplication] = useState<IApplicationData[]>();
  const [licenseType, setLicenseType] = useState(null);
  useEffect(() => {
    AllCompanyName();
    AllApplicationName();
  }, []);

  const AllApplicationName = () => {
    HTTPService.GET(`/License/AllApplicationNames`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: IApplicationData[] = JSON.parse(response.data);
        console.log(tmp);
        tmp.map((elem, index) => {
          elem['key'] = index;
        });
        console.log(tmp);
        setApplication(tmp);
      })
      .catch((error) => {
        console.error('Application error:', error);
      });
  };

  const AllCompanyName = () => {
    HTTPService.GET(`/Company/AllCompanyNames`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: ICompany[] = JSON.parse(response.data);
        console.log(tmp);
        tmp.map((elem, index) => {
          elem.key = index;
        });
        console.log(tmp);
        setCompanyData(tmp);
      })
      .catch((error) => {
        console.error('Grup adları çekme hatası:', error);
      });
  };

  const LicenseRegister = (values: any) => {
    let companyIdToUse;
    if (props.loginUserDetails.role.includes('AltisAdmin')) {
      companyIdToUse = values.selectedCompany;
    } else {
      companyIdToUse = props.loginUserDetails.companyId;
    }
    let licenseTypeProps: any = {};

    licenseTypeProps.deviceLimit = values.deviceLimit;

    const newDevice = {
      companyId: companyIdToUse,
      licenseName: values.licenseName,
      licenseType: values.licenseType,
      licenseLimit: values.licenseLimit,
      licenseExpiryDate: values.licenseExpiryDate,
      applicationId: applicationId,
      licenseTypeProps: licenseTypeProps,
    };

    HTTPService.POST(`/License/LicenseRegister`, newDevice)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Licence Add Successfully');
          window.location.replace('/admin/licence-management');
        } else {
          toast.error(response.data);
        }
      })
      .catch((error) => {
        console.error('Lisans kaydı başarısız: ', error);
      });
  };

  const handleCancel = () => {
    window.location.replace('/admin/licence-management/');
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>Register Licence</h3>
      <Form layout='vertical' onFinish={LicenseRegister}>
        {props.loginUserDetails.role.includes('AltisAdmin') ? (
          <Form.Item
            name='selectedCompany'
            rules={[
              {
                required: true,
                message: 'Please select a company!',
              },
            ]}>
            <Select showSearch placeholder='Company Name'>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}

        <Form.Item
          name='applicationName'
          rules={[{ required: true, message: 'Please input your Application Name!' }]}>
          <Select
            placeholder='Application Name'
            allowClear // Seçimi temizlemeye izin vermek için
            className='rounded-select'
            style={{ borderRadius: '20px !important' }}
            onChange={(value) => setApplicationId(value)}>
            {Array.isArray(application) &&
              application.map((group) => (
                <Select.Option key={group.applicationId} value={group.applicationId}>
                  {group.applicationName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name='licenseName'
          rules={[
            {
              required: true,
              message: 'Please input your Licence Name!',
              whitespace: true,
            },
          ]}>
          <Input prefix={<UserOutlined />} placeholder='Licence Name' style={{ borderRadius: 0 }} />
        </Form.Item>

        <Form.Item
          name='licenseType'
          rules={[
            {
              required: true,
              message: 'Please select your Licence Type!',
            },
          ]}>
          <Select
            showSearch
            placeholder='Licence Type'
            onChange={(value) => setLicenseType(value)}
            value={licenseType}>
            <Select.Option value={0}>Trial Licence</Select.Option>
            <Select.Option value={1}>Perpetual Licence</Select.Option>
            <Select.Option value={2}>Subscription Licence</Select.Option>
            <Select.Option value={100}>Support Licence</Select.Option>
            <Select.Option value={101}>Feature Licence</Select.Option>
            <Select.Option value={102}>Cal Licence</Select.Option>
          </Select>
        </Form.Item>

        {licenseType === 102 && <CalLicenseTag />}

        <Form.Item
          name='licenseLimit'
          rules={[
            {
              required: true,
              message: 'Please select your Licence Limit!',
            },
          ]}>
          <Input
            prefix={<LockOutlined />}
            placeholder='Licence Limit'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.licenseType !== currentValues.licenseType
          }>
          {({ getFieldValue }) => {
            const licenseType = getFieldValue('licenseType');
            return licenseType === 1 ? null : (
              <Form.Item
                name='licenseExpiryDate'
                rules={[
                  {
                    required: true,
                    message: 'Please select Licence Expiry Date!',
                  },
                ]}>
                <DatePicker
                  style={{ width: '100%', borderRadius: 0 }}
                  placeholder='Licence Expiry Date'
                  format='DD.MM.YYYY '
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item>
          <Row gutter={[8, 8]} justify='end' align='middle'>
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type='primary' style={{ borderRadius: 0 }}>
                Cancel
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
                Register
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default AddLicense;
