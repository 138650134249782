import { IAuthentication } from "../../interfaces/authentication";

import {REMOVE_USER, SET_USER, UPDATE_USER, UserActionTypes} from "./types";

const initialState: IAuthentication = {} as IAuthentication;

export const userReducer = (state: IAuthentication = initialState, action: UserActionTypes) => {
    switch (action.type) {
        case SET_USER:
            return { ...action.payload };
        case UPDATE_USER:
            return { ...state, ...action.payload };
        case REMOVE_USER:
            return { ...initialState };
        default:
            return { ...state};
    }
};