import { useEffect, useState } from 'react';
import { IPageProps } from '../../../interfaces/page-data';
import HTTPService from '../../../utils/makerequest';
import { ILicenseData } from '../../../interfaces/ILicenseData';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import moment from 'moment';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import CalLicenseTag from '../../../layouts/components/LicenseTypeProps/CalLicenseTag';

const UpdateLicense: React.FunctionComponent<IPageProps> = (props) => {
  const { licenseId } = useParams<any>();
  const [licenseData, setLicenseData] = useState<ILicenseData>();
  const [licenseProps, setLicenseProps] = useState<number>(null);
  //const [licenseType, setLicenseType] = useState<number>(null);

  useEffect(() => {
    LicenseData(licenseId);
  }, [licenseId]);

  const LicenseData = (licenseId: number) => {
    HTTPService.GET(`/License/GetLicenseById?licenseId=` + licenseId)
      .then((res) => {
        if (!res.data) {
          return;
        }

        let tmp: ILicenseData = JSON.parse(res.data);
        console.log(tmp);
        setLicenseData(tmp);
        setLicenseProps(tmp.licenseType);
      })
      .catch((error) => {
        console.error('API çağrısında bir hata oluştu:', error);
      });
  };

  const update = (e: any) => {
    let licenseTypeProps = {};

    licenseTypeProps = {
      ...(e.deviceLimit && { deviceLimit: e.deviceLimit }),
    }
    const newLicense = {
      licenseId: licenseId,
      licenseName: e.licenseName,
      licenseType: licenseProps,
      licenseLimit: e.licenseLimit,
      licenseExpiryDate: e.licenseExpiryDate,
      licenseTypeProps: licenseTypeProps
    };

    HTTPService.PUT(`/License/UpdateLicense`, newLicense)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success('Licence updated.');
          window.location.replace('/admin/licence-management');
        } else {
          toast.error('Failed to Update Licence');
        }
      })
      .catch((error) => {
        toast.error('There was an error!', error);
      });
  };
  const handleCancel = () => {
    window.location.replace('/admin/licence-management/');
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>Change Licence</h3>
      {}
      <Form
        layout='vertical'
        onFinish={update}
        fields={[
          {
            name: ['licenseName'],
            value: licenseData?.licenseName,
          },
          // {
          //   name: ['licenseType'],
          //   value: licenseData?.licenseType,
          // },
          {
            name: ['licenseLimit'],
            value: licenseData?.licenseLimit,
          },
          {
            name: ['licenseExpiryDate'],
            value: moment(licenseData?.licenseExpiryDate),
          },
          {
            name: ['selectedCompany'],
            value: licenseData?.companyId,
          },
          {
            name: ['deviceLimit'],
            value: licenseData?.licenseTypeProps?.deviceLimit,
          },
        ]}>
        <Form.Item
          name='licenseName'
          rules={[
            {
              required: true,
              message: 'Please input your Licence Name!',
              whitespace: true,
            },
          ]}>
          <Input prefix={<UserOutlined />} placeholder='Licence Name' style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          //name='licenseType'
          rules={[
            {
              required: true,
              message: 'Please select your Licence Type!',
            },
          ]}>
          <Select showSearch 
          placeholder='Licence Type'
          onChange={(value) => setLicenseProps(value)}
            value={licenseProps}>
            <Select.Option value={0}>Trial Licence</Select.Option>
            <Select.Option value={1}>Perpetual Licence</Select.Option>
            <Select.Option value={2}>Subscription Licence</Select.Option>
            <Select.Option value={100}>Support Licence</Select.Option>
            <Select.Option value={101}>Feature Licence</Select.Option>
            <Select.Option value={102}>Cal Licence</Select.Option>
          </Select>
        </Form.Item>
        {licenseProps === 102 && <CalLicenseTag />}
        <Form.Item
          name='licenseLimit'
          rules={[{ required: true, message: 'Please input your Licence Limit!' }]}>
          <Input
            prefix={<LockOutlined />}
            placeholder='Licence Limit'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>

        <Form.Item
          //noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.licenseProps !== currentValues.licenseProps
          }>
              <Form.Item
              hidden={licenseProps === 1}
                name='licenseExpiryDate'
                rules={[
                  {
                    required: true,
                    message: 'Please select Licence Expiry Date!',
                  },
                ]}>
                <DatePicker
                  style={{ width: '100%', borderRadius: 0 }}
                  placeholder='Licence Expiry Date'
                  format='DD.MM.YYYY '
                />
              </Form.Item>
              
        </Form.Item>
        <Form.Item>
          <Row gutter={[8, 8]} justify='end' align='middle'>
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type='primary' style={{ borderRadius: 0 }}>
                Cancel
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
                Save changes
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default UpdateLicense;
