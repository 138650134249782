import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Button, Upload, Popconfirm } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { IPageProps } from '../../../interfaces/page-data';
import { useParams } from 'react-router-dom';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { saveAs } from 'file-saver';

const TransferLicense: React.FunctionComponent<IPageProps> = (props) => {
  const { deviceId } = useParams<any>();
  const [token, setToken] = useState('');
  const formRef = useRef(null);
  const finishRef = useRef(null);
  const [isFinishTransferVisible, setIsFinishTransferVisible] = useState(false);
  const [step , setStep] = useState('');
  const [isError, setIsError] = useState(true);

  useEffect(() => {
    GetTransfer();
  }, [deviceId]);


  const GetTransfer = () => {
    HTTPService.GET(`/Token/step2Check?deviceId=${deviceId}`)
      .then((response) => {
        if (response.status === 200) {
          const tmp = JSON.parse(response.data);
          const transferToken = tmp.token;
          setToken(transferToken);
          setIsError(false);
        } else if (response.status === 400) {
          setIsError(true);
        }
      })
      .catch((error) => {
        console.error('Token Error:', error);
      });
  };


  const TransferLicense = (values) => {
    var token = values.transferLicense;
    console.log(deviceId);
    HTTPService.GET(
      `/Token/transfer/step1?deviceId=${deviceId}&token=${encodeURIComponent(token)}`
    )
      .then((response) => {
        if (response.status === 200) {
          const tmp = JSON.parse(response.data);
          const token = tmp.token;
          console.log(token);
          setToken(token);
          setIsError(false);
          toast.success('Transfer Licence successfully');
        } else if (response.status === 400) {
          toast.error(response.data);
        }
      })
      .catch((error) => {
        console.error('Transfer Licence Error:', error);
      });
  };

  const handleFormSubmit = (values: any) => {
    formRef.current.submit();
  };

  const handleTransfer = (info) => {
    if(info.file.status !== "uploading" ){
    const formData = new FormData();
    formData.append('deviceId', deviceId);
    formData.append('token', info.file.originFileObj);

    HTTPService.POST(`/Token/transfer/step1/File?deviceId=${deviceId}`, formData)
      .then((response) => {
        if (response.status === 200) {
          const tmp = JSON.parse(response.data);
          const token = tmp.token;
        toast.success('File uploaded successfully');
        setToken(token);
        setIsError(false);
    }})
      .catch((error) => {
        toast.error('Filer error');
      });
    }
  };

  const handleUploadChange =(info)=>{
    if(info.file.status !== "uploading" ){
    const formData = new FormData();
    formData.append('deviceId', deviceId);
    formData.append('token', info.file.originFileObj);

    HTTPService.POST(`/Token/transfer/step3/File?deviceId=${deviceId}`, formData)
      .then((response) => {
        if (response.status === 200) {
        toast.success('File uploaded successfully');
        window.location.replace('/admin/licence-management/');
    }})
      .catch((error) => {
        toast.error('Filer error');
      });
    }
  };

  const handleFinishSubmit = (values: any) => {
    finishRef.current.submit();
  };

  const handleFinishTransfer = (values) => {
    var token = values.finishLicense;
    console.log(deviceId);
    HTTPService.GET(
      `/Token/transfer/step3?deviceId=${deviceId}&token=${encodeURIComponent(token)}`
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success('Finish Transfer successfully');
          window.location.replace('/admin/licence-management/');
        } else if (response.status === 400) {
          toast.error(response.data);
        }
      })
      .catch((error) => {
        console.error('Finish Transfer Error:', error);
      });
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(token);
    toast.success('Token copied to clipboard');
  };

  const Download = async () => {
    try {
      let fileName = 'KeyFile';
      const blob = new Blob([token], { type: 'application/octet-stream' });
      saveAs(blob, fileName);
    } catch (error) {
      toast.error('Download Error');
    }
  };

  return (
    <div>
      
      <div
        style={{
          padding: '20px',
          maxWidth: '800px',
          height: 'auto',
          margin: 'auto',
          border: '1px solid black',
        }}>
        <h3 style={{ color: 'red' }}>Transfer Licence</h3>
        <div>
          <Form ref={formRef} onFinish={TransferLicense}>
            <Form.Item
              name='transferLicense'
              label='Transfer Licence'
              style={{ marginBottom: '30px' }}>
              <Input placeholder='Enter your transfer licence' />
            </Form.Item>

            <Form.Item
              name='uploadTransfer'
              label={'Upload File' + Array(8).fill('\u00A0').join('')}
              valuePropName='fileList'
              getValueFromEvent={(e) => {
                if (Array.isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
              style={{ marginBottom: '30px', width: '300px', height: '50px' }}>
              <Upload name='logo'  listType='text' onChange={handleTransfer}>
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>

            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                <Popconfirm
                  title='Are you sure you want to complete the transfer?'
                  onConfirm={handleFormSubmit}
                  okText='Yes'
                  cancelText='No'>
                  <Button type='primary' htmlType='submit'>
                    Transfer
                  </Button>
                </Popconfirm>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div style={{ height: '40px' }}></div>

      {!isError && (
        <div
          style={{ padding: '20px', maxWidth: '800px', margin: 'auto', border: '1px solid black' }}>
          <h3 style={{ color: 'red' }}>Key</h3>
          <div>
            <p style={{ width: '750px', height: '150px' }}>{token}</p>
          </div>
          <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
              <Button
                type='primary'
                onClick={() => {
                  handleCopyToClipboard();
                  setIsFinishTransferVisible(true);
                }}>
                Copy{' '}
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  Download();
                  setIsFinishTransferVisible(true);
                }}>
                Download{' '}
              </Button>
            </div>
          </div>
        </div>
      )}
      <div style={{ height: '20px' }}></div>
      {isFinishTransferVisible && (
        <div
          style={{
            padding: '20px',
            maxWidth: '800px',
            height: 'auto',
            margin: 'auto',
            border: '1px solid black',
            marginTop: '20px',
          }}>
          <h3 style={{ color: 'red' }}>Finish Transfer</h3>
          <div>
            <Form ref={finishRef} onFinish={handleFinishTransfer}>
              <Form.Item
                name='finishLicense'
                label='Finish Transfer'
                style={{ marginBottom: '30px' }}>
                <Input placeholder='Enter your finish transfer licence' />
              </Form.Item>

              <Form.Item
                name='upload'
                label={'Upload File' + Array(8).fill('\u00A0').join('')}
                valuePropName='fileList'
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  return e?.fileList;
                }}
                style={{ marginBottom: '30px', width: '300px', height: '50px' }}>
                <Upload
                  name='logo'
                  listType='text'
                  onChange={handleUploadChange}>
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>

              <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                  <Popconfirm
                    title='Are you sure you want to finish the transfer?'
                    onConfirm={handleFinishSubmit}
                    okText='Yes'
                    cancelText='No'>
                    <Button type='primary' htmlType='submit'>
                      Finish Transfer
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransferLicense;
