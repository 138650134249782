import { useEffect, useState } from 'react';
import { IPageProps } from '../../../interfaces/page-data';
import HTTPService from '../../../utils/makerequest';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { IApplicationData } from '../../../interfaces/IApplicationData';

const UpdateApplication: React.FunctionComponent<IPageProps> = (props) => {
  const { applicationId } = useParams<any>();
  const [applicationData, setApplicationData] = useState<IApplicationData>();

  useEffect(() => {
    ApplicationData(applicationId);
  }, [applicationId]);

  const ApplicationData = (applicationId: number) => {
    HTTPService.GET(`/Application/GetApplication?applicationId=` + applicationId)
      .then((res) => {
        if (!res.data) {
          return;
        }

        let tmp: IApplicationData = JSON.parse(res.data);
        console.log(tmp);
        setApplicationData(tmp);
      })
      .catch((error) => {
        console.error('API çağrısında bir hata oluştu:', error);
      });
  };

  const update = (e: any) => {
    const newApplication = {
      applicationId: applicationId,
      applicationName: e.applicationName,
      applicationExplanation: e.applicationExplanation,
    };

    HTTPService.PUT(`/Application/UpdateApplication`, newApplication)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success('Application updated.');
          window.location.replace('/admin/application-management');
        } else {
          toast.error('Failed to Update Application');
        }
      })
      .catch((error) => {
        toast.error('There was an error!', error);
      });
  };
  const handleCancel = () => {
    window.location.replace('/admin/application-management/');
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>Change Application</h3>
      {}
      <Form
        layout='vertical'
        onFinish={update}
        fields={[
          {
            name: ['applicationName'],
            value: applicationData?.applicationName,
          },
          {
            name: ['applicationExplanation'],
            value: applicationData?.applicationExplanation,
          },
        ]}>
        <Form.Item
          name='applicationName'
          rules={[
            {
              required: true,
              message: 'Please input your Application Name!',
              whitespace: true,
            },
          ]}>
          <Input
            prefix={<UserOutlined />}
            placeholder='Application Name'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>

        <Form.Item
          name='applicationExplanation'
          rules={[
            {
              required: true,
              message: 'Please input your Application Explanation!',
              whitespace: true,
            },
          ]}>
          <Input
            prefix={<UserOutlined />}
            placeholder='Application Explanation'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>

        <Form.Item>
          <Row gutter={[8, 8]} justify='end' align='middle'>
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type='primary' style={{ borderRadius: 0 }}>
                Cancel
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
                Save changes
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default UpdateApplication;
