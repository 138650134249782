import { useEffect, useState } from 'react';
import { IPageData, IPageProps } from '../../../interfaces/page-data';
import AltisDataGrid, {
  IAltisDataGridColumns,
  IDropdownItem,
} from '../../../AltisComponent/AltisDataGrid';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import {
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
  EditTwoTone,
  EyeTwoTone,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { ILicense } from '../../../interfaces/ILicenseData';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';

const LicenseManagement: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const [licenseData, setLicenseData] = useState<ILicense>();

  const pageData: IPageData = {
    title: 'Licence Management',
    loaded: true,
  };

  useEffect(() => {
    onSetPage(pageData);
    LicenseFilter();
  }, []);

  //   {
  //     id: 0,
  //     name: 'UpdateLicense',
  //   },
  //   {
  //     id: 1,
  //     name: 'CoreLicense',
  //   },
  //   {
  //     id: 2,
  //     name: 'SupportLicense',
  //   },
  // ];

  const dropdownLicenseType: IDropdownItem[] = [
    {
      id: 0,
      name: 'Trial Licence',
    },
    {
      id: 1,
      name: 'Perpetual Licence',
    },
    {
      id: 2,
      name: 'Subscription Licence',
    },
    {
      id: 100,
      name: 'Support Licence',
    },
    {
      id: 101,
      name: 'Feature Licence',
    },
    {
      id: 102,
      name: 'Cal Licence',
    },
  ];

  const columns: IAltisDataGridColumns[] = [
    {
      title: 'Company Name',
      key: 'companyName',
      placeHolder: 'Company Name',
      inputType: 'input',
      align:'left'
    },
    {
      title: 'Application Name',
      key: 'applicationName',
      placeHolder: 'Application Name',
      inputType: 'input',
      align:'left'
    },
    {
      title: 'Licence Name',
      key: 'licenseName',
      placeHolder: 'Licence Name',
      inputType: 'input',
      align:'left'
    },
    {
      title: 'Licence Type',
      key: 'licenseType',
      placeHolder: 'Licence Type',
      inputType: 'dropdown',
      dropdownItem: dropdownLicenseType,
      width: 1000,
      align:'left'
    },
    {
      title: 'Licence Limit',
      key: 'licenseLimit',
      placeHolder: 'Licence Limit',
      inputType: 'input',
    },
    {
      title: 'Licence Key',
      key: 'licenseGroupKey',
      placeHolder: 'Licence Group Key',
      inputType: 'input',
    },
    {
      title: 'Licence Expiry Date',
      key: 'licenseExpiryDate',
      placeHolder: 'Licence Expiry Date',
      inputType: 'date',
      width: 1000,
    },

    {
      title: 'Operations',
      key: 'operations',
      placeHolder: 'operations',
      inputType: 'operations',

      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
           <Space size={5}>
          <Popconfirm
            title={
              <div>
                Are you sure you want to delete this licence? <br />
                It will be deleted along with the licence on devices connected to this licence.
              </div>
            }
            onConfirm={() => {
              DeleteLicense(record.licenseId);
              console.log(record);
            }}
            okText='Evet'
            cancelText='Hayır'>
            <Tooltip title='Delete'>
              <Button
                size='middle'
                icon={<DeleteTwoTone />}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                disabled={props.loginUserDetails.role !== 'AltisAdmin'}></Button>
            </Tooltip>
          </Popconfirm>
          <Tooltip title='Update'>
            <Button
              // shape='circle'
              size='middle'
              icon={<EditTwoTone />}
              onClick={() => {
                const licenseId = record.licenseId;
                window.location.replace('/admin/update-licence/' + licenseId);
              }}
              disabled={props.loginUserDetails.role !== 'AltisAdmin'}></Button>
          </Tooltip>
          <Tooltip title='Details'>
            <Button
              // shape='circle'
              size='middle'
              icon={<EyeTwoTone />}
              onClick={() => {
                const licenseId = record.licenseId;
                window.location.replace('/admin/device-licence/' + licenseId);
              }}></Button>
          </Tooltip>
          </Space>
        </div>
      ),
    },
  ];

  const DeleteLicense = (id: any) => {
    HTTPService.DELETE(`/License/deleteLicense?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success('Licence deletion successful.');
        window.location.reload();
      } else {
        toast.error('Licence deletion failed.');
      }
    });
  };
  const LicenseFilter = (e?: any) => {
    var body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      filterLicenseName: e?.licenseName,
      filterLicenseLimit: e?.licenseLimit,
      companyId: e?.companyId,
      companyName: e?.companyName,
      applicationId: e?.applicationId,
      applicationName: e?.applicationName,
      filterLicenseType: e?.licenseType,
      licenseGroupKey: e?.licenseGroupKey,
      filterStartTime:
        e?.licenseExpiryDate_start === null || e?.licenseExpiryDate_start === ''
          ? null
          : new Date(Date.parse(e?.licenseExpiryDate_start)),
      filterFinishTime:
        e?.licenseExpiryDate_finish === null || e?.licenseExpiryDate_finish === ''
          ? null
          : new Date(Date.parse(e?.licenseExpiryDate_finish)),
    };

    HTTPService.POST(`/License/LicenseFilter`, body)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: ILicense = JSON.parse(response.data);
        tmp.data = tmp.data?.filter((elem) => !elem.isDeleted);
        tmp.data?.map((elem, index) => {
          elem.key = index;
        });
        setLicenseData(tmp);
      })
      .catch((err) => {
        console.error(err);
        toast.error('Bir Hata Oluştu');
      });
  };

  return (
    <>
      <div className='row'>
        <div className='col-md-4 mb-3'>
          {props.loginUserDetails.role === 'AltisAdmin' && (
            <Button
              type='primary'
              shape='round'
              style={{ height: 40, borderRadius: '5px 30px 30px 5px', marginBottom: 20 }}
              size='middle'
              onClick={() => window.location.replace('/admin/add-licence')}>
              Add New Licence
            </Button>
          )}
        </div>
      </div>
      <div className='row'>
        {licenseData != null ? (
          <div className='col-md-12'>
            {
              <AltisDataGrid
                data={licenseData!.data}
                total={licenseData!.count}
                columns={columns}
                onChange={LicenseFilter}
              />
            }
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default LicenseManagement;
