import React, {CSSProperties} from 'react';

import './Logo.scss';
import altisLogo from './../../../assets/img/altisLogo.png'
import altisLogoLight from './../../../assets/img/altisLogo-light.png'
import altisLogoBeyaz from './../../../assets/img/altisLogo_beyaz.png'
import altisbeyaz from './../../../assets/img/altisbeyaz.png'
import LicenceLogo from './../../../assets/img/LicenceLogo.png'



interface LogoProps {
  alt?: string;
  light?: boolean;
  width?: number | string;
  height?: number | string;
  source?: string;
  style?: CSSProperties;
  marginLeft?: number |string;
  padding?: number | string;
}

const Logo: React.FunctionComponent<LogoProps> = props => {
  const { source, style } = props;


  return (
          <div style={style}>
            <div className='logo-wrap'>
              <img 
                src={source === "altisLogoBeyaz" ?altisLogoBeyaz :(source === "altisbeyaz" ?altisbeyaz :(source === "altisLogo"?altisLogo :(source==="altisLogoLight" ? altisLogoLight : (source === "LicenceLogo" ? LicenceLogo : LicenceLogo )) )) } 
                alt={props.alt} 
                width={props.width} 
                height={props.height}
                style ={{padding:props.padding,marginLeft:props.marginLeft}}/>
            </div>
          </div>
  )
};

Logo.defaultProps = {
  width: 'auto',
  height: 'auto',
  light: false,
  alt: ''
};

export default Logo;
