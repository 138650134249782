import { createStore, combineReducers } from 'redux';
import {createBrowserHistory} from 'history';
import { settingsReducer } from './settings/reducer';
import { pageDataReducer } from './pages/reducer';
import { connectRouter } from 'connected-react-router'
import { userReducer } from "./user/reducer";

export const history = createBrowserHistory({
  basename: '/'
});

const rootReducer = combineReducers({
  settings: settingsReducer,
  pageData: pageDataReducer,
  router: connectRouter(history),
  user: userReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer);
