import { useEffect, useState } from 'react';
import { IPageProps } from '../../../interfaces/page-data';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { ICompany } from '../../../interfaces/ICompany';
import { IDeviceLicense } from '../../../interfaces/IDeviceLicense';
import {
  LaptopOutlined,
  UserOutlined,
} from '@ant-design/icons';

const AddDevice: React.FunctionComponent<IPageProps> = (props) => {
  const [deviceLicense, setDeviceLicense] = useState<IDeviceLicense[]>();
  const [selectedLicenseId, setSelectedLicenseId] = useState<number | null>(null);
  const [companyData, setCompanyData] = useState<ICompany[]>();
  useEffect(() => {
    AllCompany();
    AllLicenseName('');
  }, []);

  const AllLicenseName = (value) => {
    let companyIdToUse;

    if (props.loginUserDetails.role === 'AltisAdmin') {
      companyIdToUse = value;
    } else {
      companyIdToUse = props.loginUserDetails.companyId;
    }

    HTTPService.GET(`/Device/AllLicenseNames?companyId=${companyIdToUse}`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: IDeviceLicense[] = JSON.parse(response.data);
        console.log(tmp);
        tmp.map((elem, index) => {
          elem['key'] = index;
        });
        console.log(tmp);
        setDeviceLicense(tmp);
      })
      .catch((error) => {
        console.error('Company çekme hatası:', error);
      });
  };

  const AllCompany = () => {
    HTTPService.GET(`/Company/AllCompanyNames`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: ICompany[] = JSON.parse(response.data);
        console.log(tmp);
        tmp.map((elem, index) => {
          elem['key'] = index;
        });
        console.log(tmp);
        setCompanyData(tmp);
      })
      .catch((error) => {
        console.error('Grup adları çekme hatası:', error);
      });
  };

  const DeviceRegister = (values: any) => {
    let companyIdToUse;
    if (props.loginUserDetails.role === 'AltisAdmin') {
      companyIdToUse = values.selectedCompany;
    } else {
      companyIdToUse = props.loginUserDetails.companyId;
    }

    const newDevice = {
      companyId: companyIdToUse,
      deviceName: values.deviceName,
      macAddress: values.macAddress,
      hddSerialNumber: values.hddSerialNumber,
      cpuSerialNumber: values.cpuSerialNumber,
      deviceSerialNumber: values.deviceSerialNumber,
      licenseId: selectedLicenseId,
    };

    HTTPService.POST(`/Device/DeviceRegister`, newDevice)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Device Add Successfully');
          window.location.replace('/admin/licence-management/');
        } else if (response.status === 400) {
          toast.error(response.data);
        }
      })
      .catch((error) => {
        console.error('Cihaz kaydı başarısız: ', error);
      });
  };

  const handleCancel = () => {
    window.location.replace('/admin/licence-management/');
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>Register Device</h3>
      <Form layout='vertical' onFinish={DeviceRegister}>
        {props.loginUserDetails.role.includes('AltisAdmin') ? (
          <Form.Item
            name='selectedCompany'
            rules={[
              {
                required: true,
                message: 'Please select a company!',
              },
            ]}>
            <Select
              showSearch
              placeholder='Company Name'
              onChange={(value) => AllLicenseName(value)}>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        {props.loginUserDetails.role.includes('AltisAdmin') ? (
          <Form.Item
            name='licenseName'
            rules={[{ required: true, message: 'Please input your Licence Name!' }]}>
            <Select
              placeholder='Licence Name'
              allowClear // Seçimi temizlemeye izin vermek için
              className='rounded-select'
              style={{ borderRadius: '20px !important' }}
              onChange={(value) => setSelectedLicenseId(value)}>
              {Array.isArray(deviceLicense) &&
                deviceLicense.map((group) => (
                  <Select.Option key={group.licenseId} value={group.licenseId}>
                    {group.licenseName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        ) : null}

       

        <Form.Item
          name='deviceName'
          rules={[
            {
              required: true,
              message: 'Please input your Device Name!',
              whitespace: true,
            },
          ]}>
          <Input prefix={<UserOutlined />} placeholder='Device Name' style={{ borderRadius: 0 }} />
        </Form.Item>

        {/* <Form.Item
          name='macAddress'
          >
          <Input prefix={<WifiOutlined />} placeholder='Mac Addres' style={{ borderRadius: 0 }} />
        </Form.Item>

        <Form.Item
          name='hddSerialNumber'
          >
          <Input
            prefix={<HddOutlined />}
            placeholder='HDD Serial Number'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>

        <Form.Item
        name='cpuSerialNumber'
          >
          <Input
            prefix={<DesktopOutlined />}
            placeholder='CPU Serial Number'
            style={{ borderRadius: 0 }}
          />
        </Form.Item> */}

        <Form.Item
          name='deviceSerialNumber'
          rules={[
            {
              required: true,
              message: 'Please input your Device Serial Number!',
              whitespace: true,
            },
          ]}>
          <Input
            prefix={<LaptopOutlined />}
            placeholder='Device Serial Number'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>

        <Form.Item>
          <Row gutter={[8, 8]} justify='end' align='middle'>
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type='primary' style={{ borderRadius: 0 }}>
                Cancel
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
                Register
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default AddDevice;
