import React, { useEffect, useState } from "react";
import { Card, Form, Input, Button, Select, Col, Row } from "antd";
import { UserOutlined, LockOutlined, HomeOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { IPageData, IPageProps } from "../../../interfaces/page-data";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { cities } from "../../../utils/Constants";

const { Option } = Select;

const CompanyManagement: React.FunctionComponent<IPageProps> = props => {
  const { onSetPage } = props;
  const [companyData, setCompanyData] = useState<ICompanyData>({});

  const pageData: IPageData = {
    title: 'Company Management',
    loaded: true
  };
  useEffect(() => {
    onSetPage(pageData);
    getCompanyData();
  }, []);


  const getCompanyData = () => {

    HTTPService.GET(`/Company/GetCompanyId?companyId=${props.loginUserDetails.companyId}`)
      .then((res) => {
        if (!res.data) {
          return;
        }
        let tmp: ICompanyData = JSON.parse(res.data);
        console.log(tmp)
        setCompanyData(tmp);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  };

  const updateCompany = (values: any) => {
    const updatedCompanyData = {
      companyId: props.loginUserDetails.companyId,
      companyName: values.companyName,
      companyType: values.companyType,
      location: values.location,
      locationCity: values.city,
      responsibleFirstname: values.responsibleFirstname,
      responsibleLastname: values.responsibleLastname,
      responsibleTelephone: values.responsibleTelephone,
      responsibleEmail: values.responsibleEmail
    };
    HTTPService.PUT(`/Company/UpdateCompany`, updatedCompanyData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success("Company updated successfully.");
        } else {
          toast.error("Failed to update company. Please check your input.");
        }
      })
      .catch(error => {
        toast.error('There was an error!', error);
      });
  };
  return (
    <Card style={{ padding: '50px' }}>
      <Form
        layout="vertical"
        onFinish={updateCompany}
        fields={[
          {
            name: ['companyName'],
            value: companyData.companyName,
          },
          {
            name: ['companyType'],
            value: companyData.companyType,
          },
          {
            name: ['location'],
            value: companyData.location,
          },
          {
            name: ['city'],
            value: companyData.locationCity,
          },
          {
            name: ['responsibleFirstname'],
            value: companyData.responsibleFirstname,
          },
          {
            name: ['responsibleLastname'],
            value: companyData.responsibleLastname,
          },
          {
            name: ['responsibleTelephone'],
            value: companyData.responsibleTelephone,
          },
          {
            name: ['responsibleEmail'],
            value: companyData.responsibleEmail,
          },


        ]}
      >
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label="Company Name"
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Please input the company name!",
                },
              ]}
            >
              <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='Company Type'
              name='companyType'
              rules={[
                {
                  required: true,
                  message: 'Please select your Company Type!',
                },
              ]}
            >
              <Select showSearch placeholder='Company Type'>
                <Select.Option value='Store'>Store</Select.Option>
                <Select.Option value='Retaile'>Retail</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>


        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label="Address"
              name="location"
              rules={[
                {
                  required: true,
                  message: "Please input the address!",
                },
              ]}
            >
              <Input prefix={<HomeOutlined />} style={{ borderRadius: 0 }} />

            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='Location City'
              name='city'
              rules={[
                {
                  required: true,
                  message: 'Please select your city!',
                },
              ]}
            >
              <Select
                showSearch
                placeholder='Select city'
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {cities.map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Responsible Firstname"
          name="responsibleFirstname"
          rules={[
            {
              required: true,
              message: "Please input your company Responsible Firstname!",
            },
          ]}
        >
          <Input prefix={<UserOutlined />}
            style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          label="Responsible Lastname"
          name="responsibleLastname"
          rules={[
            {
              required: true,
              message: "Please input your company Responsible Lastname!",
            },
          ]}
        >
          <Input prefix={<UserOutlined />}
            style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          label="Responsible Telephone"
          name="responsibleTelephone"
          rules={[
            {
              required: true,
              message: "Please input your telephone Responsible Telephone!",
            },
          ]}
        >
          <Input prefix={<PhoneOutlined />}
            style={{ borderRadius: 0 }} />
        </Form.Item>

        <Form.Item
          label="Responsible E-Mail"
          name="responsibleEmail"
          rules={[
            {
              required: true,
              message: "Please input your Responsible E-Mail!",
            },
          ]}
        >
          <Input prefix={<MailOutlined />}
            style={{ borderRadius: 0 }} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ borderRadius: 0 }}>
            Update Information
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default CompanyManagement;
