import { useParams } from 'react-router-dom';
import { IPageProps } from '../../../interfaces/page-data';
import { useEffect, useState } from 'react';
import { IDeviceLicense } from '../../../interfaces/deviceData';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { DesktopOutlined, HddOutlined, LaptopOutlined, UserOutlined, WifiOutlined } from '@ant-design/icons';
import "../DeviceLicense/Device.css"

const UpdateDevice: React.FunctionComponent<IPageProps> = (props) => {
  const { deviceId } = useParams<any>();
  const [deviceLicense, setDeviceLicense] = useState<IDeviceLicense>();

  useEffect(() => {
    console.log(deviceId);
    DeviceData(deviceId);
  }, [deviceId]);

  const update = (e) => {
    const updatedDevice = {
      deviceId: deviceId,
      deviceName: e.deviceName,
      macAddress: e.macAddress,
      hddSerialNumber: e.hddSerialNumber,
      cpuSerialNumber: e.cpuSerialNumber,
      deviceSerialNumber: e.deviceSerialNumber,
    };
    console.log(updatedDevice);
    HTTPService.PUT(`/Device/UpdateDevice`, updatedDevice)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success('Updated Device.');
          window.location.replace('/admin/licence-management/');
        } else {
          toast.error('Device could not be updated');
        }
      })
      .catch((error) => {
        toast.error('There was an error!', error);
      });
  };

  const DeviceData = (deviceId: number) => {
    HTTPService.GET(`/Device/GetDevice?deviceId=` + deviceId)
      .then((res) => {
        if (!res.data) {
          return;
        }
        console.log(res.data);
        let tmp: IDeviceLicense = JSON.parse(res.data);
        setDeviceLicense(tmp);
      })
      .catch((error) => {
        console.error('API çağrısında bir hata oluştu:', error);
      });
  };
  const handleCancel = () => {
    window.location.replace('/admin/licence-management/');
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>Update Device</h3>
      {}
      <Form
        layout='vertical'
        onFinish={update}
        fields={[
          {
            name: ['deviceName'],
            value: deviceLicense?.deviceName,
          },
          {
            name: ['macAddress'],
            value: deviceLicense?.macAddress,
          },
          {
            name: ['hddSerialNumber'],
            value: deviceLicense?.hddSerialNumber,
          },
          {
            name: ['cpuSerialNumber'],
            value: deviceLicense?.cpuSerialNumber,
          },
          {
            name: ['deviceSerialNumber'],
            value: deviceLicense?.deviceSerialNumber,
          },
        ]}>
         
        <Form.Item
          name='deviceName'
          rules={[
            {
              required: true,
              message: 'Please input your DeviceName!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'DeviceName cannot contain spaces!',
            },
          ]}>
          <Input prefix={<UserOutlined />} placeholder='DeviceName' style={{ borderRadius: 0 }} />
        </Form.Item>
        
        {props.loginUserDetails.role.includes('AltisAdmin') ? (
        <Form.Item
          name='macAddress'
          >
          <Input
            prefix={<WifiOutlined />}
            placeholder='Mac Address'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        ) : null}
        {props.loginUserDetails.role.includes('AltisAdmin') ? (
        <Form.Item
          name='hddSerialNumber'
         >
          <Input
            prefix={<HddOutlined />}
            placeholder='HDD Serial Number'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        ) : null}
        {props.loginUserDetails.role.includes('AltisAdmin') ? (
        <Form.Item
          name='cpuSerialNumber'
          >
          <Input
            prefix={<DesktopOutlined />}
            placeholder='CPU Serial Number'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        ) : null}
        <Form.Item
          name='deviceSerialNumber'
          rules={[
            {
              required: true,
              message: 'Please input your Device Serial Number!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'Device Serial Number cannot contain spaces!',
            },
          ]}>
          <Input
            prefix={<LaptopOutlined />}
            placeholder='Device Serial Number'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        <Form.Item>
          <Row gutter={[8, 8]} justify='end' align='middle'>
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type='primary' style={{ borderRadius: 0 }}>
                Cancel
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
                Save Changes
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default UpdateDevice;
